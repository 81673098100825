import { Box, Typography, Button } from '@mui/material';
import { __get_site_list } from 'apis/site';
import { useNavigate } from 'react-router-dom';

const HeaderContent = () => {
  const navigate = useNavigate();

  const onClick = async () => {
    const res = await __get_site_list();
    if (res.length > 0) {
      navigate(`/site/${res[0].siteId}`);
    } else {
      alert('사이트가 없습니다.');
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Typography variant="h5" sx={{ ml: 2 }}>
        스마트계측 모니터링 시스템
      </Typography>

      <Button
        variant="outlined"
        onClick={onClick}
      >
        모니터링
      </Button>
    </Box>
  );
};

export default HeaderContent;
