import { lazy } from 'react';
import Loadable from 'components/Loadable';
import PrivateRoute from './PrivateRoute';

const Sensor = Loadable(lazy(() => import('pages/sensor')));

const SensorRoutes = {
  element: <PrivateRoute isAdminLayout={false} />,
  children: [
    {
      path: '/:siteId/sensor/:sensorType/:sensorId',
      element: <Sensor />,
    },
  ],
};

export default SensorRoutes;
