import { lazy } from 'react';
import Loadable from 'components/Loadable';
import LoginCheckRoute from './LoginCheckRoute';

const AuthLogin = Loadable(lazy(() => import('pages/auth/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/Register')));

const LoginRoutes = {
  element: <LoginCheckRoute />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />,
    },
    {
      path: 'register',
      element: <AuthRegister />,
    },
  ],
};

export default LoginRoutes;
