import { lazy } from 'react';
import Loadable from 'components/Loadable';
import PrivateRoute from './PrivateRoute';

const AdminSite = Loadable(lazy(() => import('pages/admin/site')));
const AdminSiteCreate = Loadable(lazy(() => import('pages/admin/site/Create')));
const AdminSiteDetail = Loadable(lazy(() => import('pages/admin/site/Detail')));
const AdminSensor = Loadable(lazy(() => import('pages/admin/sensor')));
const AdminSensorCreate = Loadable(lazy(() => import('pages/admin/sensor/Create')));
const AdminSensorDetail = Loadable(lazy(() => import('pages/admin/sensor/Detail')));
const AdminUser = Loadable(lazy(() => import('pages/admin/user')));
const AdminUserCreate = Loadable(lazy(() => import('pages/admin/user/Create')));
const AdminUserDetail = Loadable(lazy(() => import('pages/admin/user/Detail')));

const AdminRoutes = {
  path: '/admin',
  element: <PrivateRoute isAdminLayout={true} />,
  children: [
    {
      path: 'site',
      element: <AdminSite />,
    },
    {
      path: 'site/create',
      element: <AdminSiteCreate />,
    },
    {
      path: 'site/list/:siteId',
      element: <AdminSiteDetail />,
    },
    {
      path: 'sensor',
      element: <AdminSensor />,
    },
    {
      path: 'sensor/create',
      element: <AdminSensorCreate />,
    },
    {
      path: 'sensor/list/:siteId/:sensorUuid/:sensorType',
      element: <AdminSensorDetail />,
    },
    {
      path: 'user',
      element: <AdminUser />,
    },
    {
      path: 'user/create',
      element: <AdminUserCreate />,
    },
    {
      path: 'user/list/:userId',
      element: <AdminUserDetail />,
    },
  ],
};

export default AdminRoutes;
