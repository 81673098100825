import { BASE_URL } from '../config.js';
import { toast } from 'react-toastify';

const http = async (method, url, params, headers) => {
  let _headers = {
    'Content-Type': 'application/json',
  };

  if (headers) {
    _headers = headers;
  }

  _headers['Authorization'] = 'Bearer ' + '';

  let body = null;
  if (params instanceof FormData) {
    body = params;
    delete _headers['Content-Type'];
  } else {
    body = JSON.stringify(params);
  }

  const response = await fetch(BASE_URL + url, {
    method: method,
    headers: _headers,
    body: body,
  });

  if (response.status === 200 || response.status === 201) {
    const json = await response.json();
    return json.data;
  } else {
    if (response.status !== 401) {
      toast('서버에서 에러가 발생했습니다.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
      });
    } else {
      window.location.href = 'https://www.moikr.com/#/login';
    }

    throw new Error('서버 에러 발생');
  }
};

export default {
  get: async (url) => await http('get', url),
  post: async (url, body, headers) => await http('post', url, body, headers),
  put: async (url, body, headers) => await http('put', url, body, headers),
  delete: async (url) => await http('delete', url),
  patch: async (url, body, headers) => await http('PATCH', url, body, headers),
};
