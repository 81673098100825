// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/dashboard/default',
  fontFamily: `'Public Sans', sans-serif`,
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
};

export const drawerWidth = 260;
export const BASE_URL_LOCAL = 'http://localhost:3001';
export const BASE_URL = 'https://www.moikr.com/inclinometer';

export const sensorTypes = [
  { name: '지중경사계', value: 'inclinometer' },
  { name: '건물경사계', value: 'tiltmeter' },
  { name: '응력계', value: 'straingauge' },
  { name: '지하수위계', value: 'waterlevelmeter' },
  { name: '층별침하계', value: 'extensometer' },
  { name: '지표침하계', value: 'settlement' },
  { name: '균열계', value: 'crackgauge' },
  { name: '간극수압계', value: 'piezometer' },
];

export default config;
