import { lazy } from 'react';
import Loadable from 'components/Loadable';
import PrivateRoute from './PrivateRoute';

const Site = Loadable(lazy(() => import('pages/site')));

const SiteRoutes = {
  path: '/',
  element: <PrivateRoute isAdminLayout={false} />,
  children: [
    {
      path: 'site/:siteId',
      element: <Site />,
    },
  ],
};

export default SiteRoutes;
