import { lazy } from 'react';
import Loadable from 'components/Loadable';
import PrivateRoute from './PrivateRoute';

const InclinometerReport = Loadable(lazy(() => import('pages/report')));

const ReportRoutes = {
  element: <PrivateRoute isAdminLayout={false} />,
  children: [
    {
      path: '/report/:siteId/:sensorType/:sensorId',
      element: <InclinometerReport />,
    },
  ],
};

export default ReportRoutes;
