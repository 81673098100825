import { __get_chklogin } from 'apis/auth';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import { __get_site_list } from 'apis/site';

const PrivateRoute = ({ isAdminLayout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        await __get_chklogin();
        if (location.pathname === '/') {
          const res = await __get_site_list();
          if (res.length > 0) {
            navigate(`/site/${res[0].siteId}`);
          } else {
            alert('사이트가 없습니다.');
          }
        }
      } catch (err) {
        console.log(err);
        navigate('/login');
      }
    })();
  }, []);

  return <>{isAdminLayout ? <MainLayout /> : <MinimalLayout />}</>;
};

export default PrivateRoute;
