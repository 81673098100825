// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import './index.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { RecoilRoot } from 'recoil';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToastContainer } from 'react-toastify';
import RecoilNexus from 'recoil-nexus';

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <RecoilRoot>
        <RecoilNexus />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Routes />
        </LocalizationProvider>
      </RecoilRoot>
    </ScrollTop>
    <ToastContainer />
  </ThemeCustomization>
);

export default App;
