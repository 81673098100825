import http from '../utils/http';
import { setRecoil } from "recoil-nexus";
import { LoginUser } from 'store/LoginUser';

export const __post_login = async (data) => {
  const res = await http.post(`/login`, data);
  return res;
};

export const __get_chklogin = async () => {
  const res = await http.get(`/chklogin`);

  setRecoil(LoginUser, res);
  return res;
};
