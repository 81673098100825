import http from '../utils/http';

export const __get_site_list = async () => {
  const res = await http.get(`/sites/list/`);
  return res;
};

export const __get_site_resource = async (activeSiteId) => {
  const res = await http.get(`/resource/list/${activeSiteId}/`);
  return res;
};

export const __get_site_resource_viewmap = async (itemSeq) => {
  const res = await http.get(`/resource/item/${itemSeq}/`);
  return res;
};

export const __get_site_resource_siteimg = async (itemSeq) => {
  const res = await http.get(`/resource/item/${itemSeq}/`);
  return res;
};
