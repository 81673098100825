// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const admin = {
  id: 'admin',
  title: '관리',
  type: 'group',
  children: [
    {
      id: 'site',
      title: '사이트 관리',
      type: 'item',
      url: '/admin/site',
      icon: icons.FontSizeOutlined,
    },
    {
      id: 'sensor',
      title: '센서 관리',
      type: 'item',
      url: '/admin/sensor',
      icon: icons.FontSizeOutlined,
    },
    {
      id: 'user',
      title: '사용자 관리',
      type: 'item',
      url: '/admin/user',
      icon: icons.FontSizeOutlined,
    },
  ],
};

export default admin;
