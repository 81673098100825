import { __get_chklogin } from 'apis/auth';
import { __get_site_list } from 'apis/site';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MinimalLayout from 'layout/MinimalLayout';

const LoginCheckRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await __get_chklogin();

        const res = await __get_site_list();
        if (res.length > 0) {
          navigate(`/site/${res[0].siteId}`);
        } else {
          alert('사이트가 없습니다.');
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return <MinimalLayout />;
};

export default LoginCheckRoute;
